.dflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterKit {
  position: relative;
  /* bottom: 0; */
  /* right: 0; */
  /* z-index: 5; */
  margin-left: 1.25rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #333333;
  min-width: 9rem;
  text-align: center;
}

.filterKit .filterArea {
  background: #fff;
  padding: 1.45rem 1.5rem;
  cursor: default;
  border-radius: 0.85rem;
  box-shadow: #00000050 0px 0px 7px 1px;
  justify-content: space-between;
}

.filterKit .filterArea svg {
  margin-left: 1.5rem;
  color: #333333;
}

.filterKit .optionContainer {
  margin-top: 0.85rem;
  border-radius: 0.85rem;
  box-shadow: #00000050 0px 0px 7px 1px;
  transform: scale(0);
  transition: 0.25s ease-out;
  transform-origin: top;
  overflow: hidden;
  position: absolute;
  width: 100%;
  display: none;
  z-index: 18;
}
.open {
  transform: scale(1) !important;
  display: block !important;
  /* animation: scaleUp 0.25s forwards; */
}

.arrowIcon {
  transition: 0.25s;
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleDowm {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.filterKit .optionContainer .option {
  padding: 0.5rem;
  background: #fff;
}

.filterKit .optionContainer .option:hover {
  cursor: pointer;
  background-color: #a79c5e;
  color: #fff;
}
