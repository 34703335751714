.formPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formBody {
  padding: 1.5rem;
  box-shadow: #a79c5e69 0px 0px 2px 2px;
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.603);
  position: relative;
  /* overflow: hidden; */
  min-height: 50vh;
}

.formBody h3 {
  font-size: 2rem;
  text-align: center;
  color: #a79c5e;
}

.formInputContainer {
  font-size: 1.25rem;
  max-width: 80vw;
  min-width: 50rem;
  margin-bottom: 0.85rem;
}

.formBody .submitButton,
.confirmationBox .btnHolder button {
  background: #a79c5e;
  border: #a79c5e solid 0.2rem;
  color: #fff;
  border-radius: 0.75rem;
  padding: 1rem 2rem;
  transition: 0.25s;
  cursor: pointer;
  position: relative;
}

.formBody .submitButton:nth-child(1) {
  margin-right: 1rem;
}
.formBody .submitButton img {
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
}

.formBody button:disabled {
  cursor: not-allowed;
  border: red solid 0.2rem;
  opacity: 0.5;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader Css */
.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.733);
  backdrop-filter: blur(2px);
  z-index: 5;
  border-radius: 0.75rem;
}

.loader img {
  max-width: 80%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.header h3 {
  margin: 0;
  padding: 0;
}

.userInfo {
  font-size: 1.65rem;
  text-transform: capitalize;
  color: #a79c5e;
}

.userInfo span:last-child {
  font-size: 1.35rem;
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

/*  */
.confirmationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #45454557;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.confirmationBox {
  padding: 2.5rem;
  box-shadow: #a79c5e69 0px 0px 2px 2px;
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  /* overflow: hidden; */
  /* min-height: 50vh; */
}

.confirmationBox .heading {
  font-size: 1.75rem;
  font-weight: bold;
}

.confirmationBox .details {
  margin: 1.5rem 0;
  background: #a79c5e;
  border-radius: 0.75rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: white;
}
.confirmationBox .details .NameContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.confirmationBox .btnHolder {
  display: flex;
  justify-content: center;
}

.confirmationBox .btnHolder button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmationBox .btnHolder button:last-child {
  margin-left: 0.75rem;
}

.confirmationBox .btnHolder button:first-child {
  background: white;
  color: #a79c5e;
}
.confirmationBox .btnHolder button:first-child img {
  filter: brightness(0.5);
}

.confirmationBox .btnHolder button img {
  max-width: 3rem;
  position: absolute;
}
