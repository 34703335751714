.toolkit {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  min-width: 9rem;
  text-align: center;
  border-radius: 5rem;
  box-shadow: #00000050 0px 0px 7px 1px;
}

.toolkit button {
  border: none;
  background: #fff;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #00000085 0px 0px 7px 1px;

  cursor: pointer;
  transition: 0.25s;
  transform: scale(1);
}

.toolkit button:hover {
  transform: scale(1.1);
}

.toolkit button img {
  width: 100%;
}

.toolkit span {
  padding: 0 1rem;
  cursor: default;
}

.dflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justifyBetween {
  justify-content: space-between;
}

/* legendsContainer */
.legendsContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
  margin: 0.5rem;
}

.legendsIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #00000085 0px 0px 7px 1px;

  width: 8rem;
  height: 8rem;
  border-radius: 50%;

  padding: 1.65rem;
  background: #a79c5e;
  border: solid 1px rgba(255, 255, 255, 0.432);

  cursor: pointer;
  box-sizing: border-box;
}
.legendsIcon img {
  width: 100%;
}

.legendsBox {
  padding: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 9rem;
  width: 15rem;
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border-radius: 0.85rem;
  box-shadow: #00000085 0px 0px 7px 1px;
  transition: 0.25s ease-in-out;
  transform: scale(0);
  margin: 0.25rem;
  transform-origin: bottom right;
  min-width: 25rem;
}

.layoutButton {
  position: absolute;
  top: 0;
  right: 0;
  background: #eeeeee;
  padding: 1rem;
  font-size: 1.5rem;
  z-index: 15;
  margin: 0.85rem;
  border-radius: 0.75rem;
  box-shadow: #00000091 0px 0px 7px 1px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.25s;
  background-color: #fff;
}

.layoutButton:hover {
  transform: scale(1.05);
}

.legendsBox h3 {
  font-size: 2rem;
}
.legendSingle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
}
.legendSingle span:first-child {
  margin-right: 1rem;
}
.legendschildContianer {
  font-size: 1.5rem;
}

.legendsBox_set {
  width: 1rem;
  height: 1rem;
  border: #000 0.18rem solid;
  min-width: 1rem;
}

.legend_open {
  transform: scale(1);
}

.modalContainer {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.349);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 55;
  left: 0;
  transition: 0.25s;
  opacity: 0;
  display: none;
}

.modalContainer_visible {
  opacity: 1;
  display: flex;
}

.modalParent {
  position: relative;
  /* width: 80%; */
  width: calc(80vh * 1.55);
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
}

.closeBtn svg {
  width: 100% !important;
  height: 100% !important;
}

.modalParent img {
  border-radius: 0.75rem;
  max-width: 100%;
  border: solid rgb(207, 207, 207) 0.2rem;
}
