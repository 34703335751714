.formPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formBody {
  padding: 1.5rem;
  box-shadow: #a79c5e69 0px 0px 2px 2px;
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.603);
  position: relative;
  /* overflow: hidden; */
  /* min-height: 50vh; */
}

.formBody h3 {
  font-size: 2rem;
  text-align: center;
  color: #a79c5e;
}

.error {
  color: red;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.formInputContainer {
  font-size: 1.25rem;
  max-width: 80vw;
  min-width: 50rem;
  margin-bottom: 0.85rem;
}

.formInputContainer input {
  padding: 1rem 0.75rem;
  border: 0;
  outline: none;
  border: 0.2rem solid #a79c5e;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.5rem;
}

.formBody .submitButton {
  background: #a79c5e;
  border: #a79c5e solid 0.2rem;
  color: #fff;
  border-radius: 0.75rem;
  padding: 1rem 2rem;
  transition: 0.25s;
  cursor: pointer;
  position: relative;
}


.formBody .submitButton img {
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
}

.formBody button:disabled {
  cursor: not-allowed;
  border: red solid 0.2rem;
  opacity: 0.5;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* Loader Css */
.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.733);
  backdrop-filter: blur(2px);
  z-index: 5;
  border-radius: 0.75rem;
}

.loader img {
  max-width: 80%;
}

@media screen and (max-width:700px) {
  .formInputContainer {
    max-width: 90%;
    min-width: 100%;
  }
  
  .formBody {
    width: 100%;
  }
  .buttonContainer {
   flex-direction: column;
  }
  
}
