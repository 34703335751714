.container {
  background-color: #fff;
  background-image: url("../../assets/images/bg.jpg");
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .logo {
  max-height: 25rem;
  transition: opacity 0.5s ease;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
