.mainContainer {
  background-color: rgb(238, 238, 238);
  background-size: cover;
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
}

.VenuBtn{
  border: none;
  padding: 1.45rem 1.5rem;
  background: #b0a461;
  color: white;
  margin: 0.25rem;
  border-radius: 8px;
  margin-left: 1rem;
  box-shadow: 0px 0px 4px 0px #00000063;
  transform: scale(1);
  transition: 0.25s;
  font-size: 1.3rem;
  font-weight: 600;
}

.VenuBtn:hover{
  transform: scale(1.05);
}

.VenuBtn:active{
  transform: scale(0.95);
}

.mainContainer_bg {
  background: url("../../assets/images/bg.jpg");
}

.childrenContainer {
  padding-top: 10rem;
  position: relative;
  width: 100vw;
  padding: 1rem 3.5rem;
  padding-top: 10rem;
  box-sizing: border-box;
}

.leftHeaderContianre {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.searchBoxConatainer {
  position: relative;
  z-index: 6;
}

.searchBox {
  font-size: 1.5rem;
  border: none;
  box-shadow: #00000063 0px 0px 4px 1px;
  border-radius: 0.7rem;
  padding: 1.5rem 1rem;
  padding-left: 4rem;
  min-width: 35rem;
  outline: none;
}

.searchBoxIcon {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translate(0%, -50%);
}

.header {
  padding: 1rem 3.5rem;
  width: 100vw;
  min-height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 20;
}

.header > img {
  /* position: absolute;
  top: 0;
  right: 0; */
  /* margin: 1rem; */
  max-height: 8rem;
}

.search_result_conatiner {
  position: absolute;
  /* padding: 1rem; */
  box-shadow: #00000063 0px 0px 4px 1px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
  /* padding-top: 1rem; */
  border-radius: 0.7rem;
  background: #fff;
}
.search_result {
  padding: 1rem 1rem;
  font-size: 1.5rem;
}

.search_result:hover {
  /* cursor: pointer; */
  background: #d1c673d5;
}

.search_result .NameContainer {
  display: flex;
  justify-content: space-between;
}

.search_result .NameContainer span:nth-child(1) {
  font-weight: 500;
}
.search_result .NameContainer span:nth-child(2) {
  color: rgb(66, 66, 66);
  font-size: 1.25rem;
}

.search_result div {
  margin: 0.35rem 0;
}

.search_result .stallNoContainer {
  font-size: 1.15rem;
}

.search_result .sectionContainer {
  font-size: 1.15rem;
}

.stallBtn {
  background: #b0a461;
  color: #fff;
  border: none;
  border-radius: 0.75rem;
  padding: 1rem 0.5rem;
  transform: scale(1);
  transition: 0.25s;
}

.stallBtn:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.MyDropDown {
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 1rem;
    flex-direction: column-reverse;
  }
  .header > img {
    max-height: 6rem;
    margin-bottom: 0.85rem;
  }
  .leftHeaderContianre {
    max-width: 100%;
    flex-wrap: wrap;
    /* gap: 0.75rem; */
  }
  .searchBox {
    min-width: unset;
    width: 100%;
  }
  .childrenContainer {
    padding: 1rem 1rem;
    padding-top: 20rem;
  }
  .searchBoxConatainer {
    width: calc(100% - 15rem);
  }
  .seachDropDown {
    margin-left: 0;
    margin-top: 1rem;
    width: 100% !important;
  }
  .VenuBtn{
    margin-top: 2rem;
  }
}
