.svgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100vw; */
  /* height: 100vh; */
}

.svgContainerBody {
  border: black solid 0.2rem;
  border-radius: 0.85rem;
  overflow: hidden;
  position: relative;
}

.svgContainerBody svg {
  width: calc(100vw - 3.5rem);
  height: calc((calc(100vw - 3.5rem)) * 0.42149);
}

.placeholders {
  pointer-events: none;
}
.placeholders div {
  position: absolute;
  width: 5px;
  height: 5px;
}

@media only screen and (max-width: 600px) {
  .svgContainerBody {
    min-height: calc(100vh - 23rem);
    max-height: calc(100vh - 23rem);
  }
  .svgContainerBody svg {
    width: calc(72vh / 0.88);
    /* width: calc(100vh / 0.42149); */
    height: calc(72vh);
  }
  /* .placeholders {
    width: calc(100vh / 0.42149);
    height: calc(100vh);
  } */
}

.testDiv {
  width: 500px;
  min-height: 500px;
  height: 100%;
}

.testDivParent {
  display: flex;
}
