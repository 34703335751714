.stallInfoContainer {
  background: #a79c5e;
  padding: 1.15rem;
  color: #fff;
  min-width: 30rem;
  border-radius: 0.8rem;
  position: fixed;
  transform: translate(-1%, calc(-100% - 0.75rem));
  /* transform: translate(0.75rem, calc(-50% - 0.85rem)); */
  z-index: 120;
}

.posLeft {
  transform: translate(-90%, calc(-100% - 0.75rem));
}

.stallInfoContainer_areaFilter {
  min-width: unset;
}

.stallInfoContainer::after {
  content: " ";

  border: 0.6rem solid transparent;
  border-top: 0;
  border-bottom: 0.6rem solid #a79c5e;
  transform: rotate(180deg);
  position: absolute;
  bottom: -0.5rem;
  left: 0.5rem;
}
.posLeft::after {
  content: " ";
  left: auto;
  right: 1.5rem;
}

.stallInfoContainer .NameContainer {
  font-size: 1.75rem;
  font-weight: bold;
  /* font-size: 1.25rem; */
  display: flex;
  justify-content: space-between;
}

.stallInfoContainer .NameContainer span:last-child {
  font-weight: 500;
  margin-left: 1.75rem;
}

.stallInfoContainer div {
  margin: 0.35rem 0;
}

.stallInfoContainer .stallNoContainer {
  font-size: 1.45rem;
}

.stallInfoContainer .sectionContainer {
  font-size: 1.45rem;
}

.mStallInfoContainer {
  background: #a79c5e;
  padding: 2rem 1rem;
  padding-top: 2.75rem;
  color: #fff;
  min-width: 250px;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  z-index: 120;
  position: fixed;
  bottom: 0;
  font-size: 1.75rem;
  width: 100%;
  box-sizing: border-box;
}
.mStallInfoContainer_open {
  animation: slideIn 0.75s forwards;
}
.mStallInfoContainer_close {
  animation: slideOut 0.5s ease-in-out forwards;
}

.mStallInfoContainer .NameContainer {
  font-size: 1.75rem;
  display: flex;
  justify-content: space-between;
}

.mStallInfoContainer .NameContainer span:nth-child(1) {
  font-weight: 500;
}

.mStallInfoContainer div {
  margin: 0.35rem 0;
}

.mStallInfoContainer .stallNoContainer {
  font-size: 1.5rem;
}

.mStallInfoContainer .sectionContainer {
  font-size: 1.5rem;
}

.closeButton {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  box-shadow: #3333339c 0px 0px 8px 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton svg {
  color: black;
  stroke-width: 3;
  transform: scale(1.15);
}

@keyframes slideIn {
  0% {
    transform: translate(-1rem, 150%);
  }
  100% {
    transform: translate(-1rem, 0%);
  }
}

@keyframes slideOut {
  0% {
    transform: translate(-1rem, 0%);
  }
  100% {
    transform: translate(-1rem, 150%);
  }
}
