.parentContainer {
  font-size: 2rem;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.venuTxt{
  text-align: center;
  width: 100%;
  font-size: 2rem;
}


.container {
  background-color: red;
  overflow: hidden;
  height: 4rem;
}

.subContiner {
  transform: translateY(0);
  transition: 0.2s transform;
}
.subContiner_anim {
  /* animation: anim infinite 6s linear forwards; */
}
.subContiner div {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: blue;
  width: 4rem;
  height: 4rem;
  border: solid 2px white;
}

.pwdType {
  color: blue;
  font-size: 1.5rem;
}
@keyframes anim {
  0% {
    transform: translateY(-0);
  }
  100% {
    transform: translateY(-90%);
  }
}

.button {
  padding: 0.5rem;
  font-size: 1.75rem;
  background: rgb(207, 124, 15);
  border: none;
  color: #fff;
  width: 10rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
